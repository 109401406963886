import { graphql, useStaticQuery } from "gatsby"

export default function OurServicesData() {
  return useStaticQuery(graphql`
    query queryOurServices {
      seo: sanitySeo(slug: { current: { eq: "services" } }) {
        meta_title
        meta_description
        keywords
        meta_image {
          asset {
            url
          }
        }
        alt_tag
      }
      hero: sanityHero(slug: { current: { eq: "our-services" } }) {
        title
        hints {
          parts
        }
        svgUpload
        isHintsAnimation
        backgroundColor {
          hex
        }
      }
      features: sanityFeatures(
        slug: { current: { eq: "why-work-with-us-our-services" } }
      ) {
        id
        slug {
          current
        }
        hints {
          parts
        }
        title
        slide
        features {
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      services: sanityServices(slug: { current: { eq: "our-services" } }) {
        title
        hints {
          parts
        }
        services_cards {
          preTitle
          title
          subTitle
          icon {
            asset {
              url
            }
          }
          backgroundColorIcon {
            hex
          }
          info
          backgroundColor {
            hex
          }
        }
      }
    }
  `)
}
