import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Layout from "../components/Layout"
import Hero from "../sections/hero/hero"
import Features from "../sections/features/features"
import MultipleCardFeatures from "../sections/multiple_card_features/multiple_card_features"
import * as hero_styles from "../sections/hero/hero_index.module.scss"
import * as feature_inline_style from "../sections/features/features_inline_4.module.scss"
import * as multiple_card_features_style from "../sections/multiple_card_features/multiple_card_features.module.scss"

import OurServicesData from "../utils/our-services-queries"
import { getSeo } from "../utils/seo"

export default function OurServices() {
  const { hero, features, services } = OurServicesData()

  return (
    <Layout navBG={hero?.backgroundColor ? hero.backgroundColor.hex : ""}>
      {/* <Seo
        title={cto.title}
        keywrods={cto.keywrods}
        description={cto.description}
      /> */}
      <Hero
        hero={hero}
        styles={hero_styles}
        nextSection={features.slug?.current}
      />
      <Features features={features} styles={feature_inline_style} />
      <MultipleCardFeatures
        features={services}
        styles={multiple_card_features_style}
      />
    </Layout>
  )
}

export const Head = () => {
  const { seo } = OurServicesData()
  return getSeo(seo)
}
